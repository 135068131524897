const Roles = Object.freeze({
  Admin: 0,
  Cashier: 1,
  User: 2,
  SubAgent: 3,
  Readonly: 4,
  Technologist: 5,
});

const TicketStatusCodes = Object.freeze({
  Unknown: 0,
  Booked: 2,
  Bought: 3,
  BookСanceled: 4,
  Void: 5,
  Returned: 6,
  NotChanged: 7,
  VoidBooked: 8,
});
const TrainTicketStatusNames = Object.freeze({
  Booked: "Booked",
  Cancelled: "Cancelled",
  WaitAuthorization: "WaitAuthorization",
  RefundAuthorized: "RefundAuthorized",
  Refunded: "Refunded",
  PartiallyRefunded: "Refunded",
  Paid: "PAID",
  Ticketed: "Ticketed",
  AwaitPayment: "AwaitPayment",
  Annulment: "Annulment",
  TicketedInProvider: "TicketedInProvider",
  PartiallyAnnulment: "PartiallyAnnulment",
  PartiallyTicketed: "PartiallyTicketed",
  Exchanged: "Exchanged",
  PartiallyExchanged: "PartiallyExchanged",
});

//  this.$enums.Endpoints.Other
const Endpoints = Object.freeze({
  Order: {
    Search: "/Order/Search",
    Fare: "/Order/Fare",
    Book: "/Order/Book",
    Buy: "/Order/Buy",
    Status: "/Order/Status",
    Update: "/Order/Update",
    Cancel: "/Order/Cancel",
    Void: "/Order/Void",
    Refund: "/Order/Refund",
    RefundData: "/Order/RefundData",
    FareRules: "/Order/FareRules",
    SeatMap: "/Order/SeatMap",
  },
  Data: {
    Orders: "/Data/Orders/Get",
    Countries: "/Data/Countries/Get",
    Stations: "/Data/Stations/Get",
    Station: "/Data/Station/Get",
    Carriers: "/Data/Carriers/Get",
    Pdf: "/Data/Pdf/Get",
    SevicePdf: "/Data/Pdf/GetService",
    QR: "/Data/QR/Get",
    TopQrSolds: "/Data/QR/Top",
    Companies: "Data/Companies/Get",
    Ballance: "Data/Ballance/Get",
    AddBallance: "Data/Company/AddBallance",
    EditTax: "Data/Company/EditTax",
  },
  Other: {
    SendMail: "/Other/Mail/Send",
    QrReport: "/Other/QR/Report",
    ExternalReceipt: "/Other/Receipt/External",
    ExternalReceiptRailway: "/Other/Receipt/TrainReceipt",
    SaleRegister: "/Other/SaleRegister/Report",
    CreateWwss: "/Other/WWSS/Create",
    CreateBlank: "Other/Blank/Create",
    CreateHotelBlank: "Other/HotelBlank/Create",
    Receipt: {
      PaymentInfo: "/Other/Receipt/PaymentInfo",
      UnionAndSend: "/Other/Receipt/UnionAndSend",
      EditLiner: "/Other/Blank/Liner/EditBlank",
    },
    Screen: {
      Get: "/Other/Screen/Get",
      Poll: "/Other/Screen/Poll",
      Set: "/Other/Screen/Set",
      Enable: "/Other/Screen/Enable",
      Disable: "/Other/Screen/Disable",
    },
  },
  Authorization: {
    Login: "/Authorization/Login",
    LoginByToken: "/Authorization/LoginByToken",
    UpdateToken: "/Authorization/UpdateToken",
    CheckToken: "/Authorization/CheckToken",
  },
  Account: {
    Get: "/Account/Get",
    Create: "/Account/Create",
    Edit: "/Account/Edit",
    Delete: "/Account/Delete",
  },
  Payment: {
    QrCreate: "/Payment/QR/Create",
    QrStatus: "/Payment/QR/Status",
    Refund: "/Payment/Refund",
  },
  Sms: {
    GetSms: "/SMS/GetAllFeedback",
  },
  RailwayData: {
    RailwayStantions: "/RailwayData/GetListCities",
    GetSchemeCar: "/RailwayData/GetSchemeCar",
    Search: "/RailwayData/Search",
    SelectCar: "/RailwayData/SelectCar",
    GetRailwayReceiptPDF: "/RailwayData/GetRailwayReceiptPDF",
  },
  RailwayOrder: {
    Book: "/RailwayOrder/Book",
    GetOrderInfo: "/RailwayOrder/GetOrderInfo",
    GetStatus: "/RailwayOrder/GetOrderStatus",
    Cancel: "/RailwayOrder/Cancel",
    Pay: "/RailwayOrder/Pay",
    GetTicketInfo: "/RailwayOrder/GetTicketInfo",
    GetRefundAmount: "RailwayOrder/GetRefundAmount",
    Refund: "/RailwayOrder/Refund",
    GetAllRailwayOrders: "/RailwayOrder/GetAllRailwayOrders",
    UpdateStatus: "/RailwayOrder/UpdateStatus",
  },
});

export default { TicketStatusCodes, Roles, Endpoints, TrainTicketStatusNames };
