<template>
  <div class="text-center">
    <v-dialog v-model="value" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 red lighten-2 white--text error-title">
          Внимание
        </v-card-title>

        <v-card-text class="mt-5 text-h6">
          <p>Сообщение: {{ error.Message }}</p>
          <p>Если вы не знаете, как самостоятельно устранить проблему, пожалуйста, свяжитесь со службой поддержки по
            номеру <span class="text-h6 orange--text">{{  }}</span> и сообщите
            указанный <span class="text-h6 orange--text">Код</span> и <span class="text-h6 orange--text">Дату</span>
          </p>
          <p v-if="error.Id">
            Код:<span class="orange--text"> {{ error.Id }}</span>
          </p>
          <p>
            Дата:<span class="orange--text"> {{ new Date() | moment }}</span>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', false)">
            ОК
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";

export default {
  name: "ErrorModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: { Id: "", Code: null, Message: "" },
    },
  },
  mixins: [DateFormat],
};
</script>

<style scoped>
.error-title {
  white-space: pre;
}
</style>
