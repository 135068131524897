import Axios from "axios";
import store from "./store";
import router from "../router";
Axios.defaults.baseURL = store.state.apiAvia;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.warn(error.response)
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (status === 401) {
      console.log("Перехватчик старт");
      await store.dispatch("updateToken");
      error.config.headers["Authorization"] = `Bearer ${store.state.token}`;
      console.log(`store ${store.state.token}`);
      console.log("Обновили токен, начинаю повторный зарпрос");
      let newReq = Axios.request(error.config);
      console.log("Повторный зарпрос завершен");
      console.log(newReq);
      return newReq;
    } 
    else if (status === 403) {
      router.push("/no-rights");
    }
    else if (status === 422)  {
      store.state.snackbar = {
        show: true,
        message: error.response.data.Message,
        color: "orange",
        timeout: 5000,
      };
      return Promise.reject(error);
    }
    else {
      console.warn(error.response.data)
      store.state.error = {
          show: true,
          value: {
            Id: error.response.data.Id,
            Code: error.response.data.StatusCode,
            Message: error.response.data.Message,
          },
        };
      return Promise.reject(error);
    }
  }
);

export default Axios;
