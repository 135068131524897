<template>
  <v-app>
    <confirm-dialog></confirm-dialog>
    <v-main>
      <div v-if="!$route.meta.noHeader" ref="header">
        <v-app-bar style="z-index: 1">
          <v-app-bar-nav-icon
            @click="drawer = true"
            v-if="$vuetify.breakpoint.smAndDown"
          >
          </v-app-bar-nav-icon>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-toolbar-title>
              <router-link to="/" style="text-decoration: none;">
                <span class="orange--text ml-2">ЛИН-ЭЙР</span>
              </router-link>
            </v-toolbar-title>

            <v-btn
              v-if="!isEmpty($store.getters.user)"
              text
              to="/find-tkt"
              class="ml-2"
              color="orange"
            >
              Найти рейс
            </v-btn>
            <v-btn
              v-if="!isEmpty($store.getters.user)"
              text
              to="/solded-tickets"
              class="ml-2"
              color="orange"
            >
              Билеты
            </v-btn>

            <v-spacer></v-spacer>
            <span
              v-if="!isEmpty($store.getters.user)"
              class="orange--text mx-5 text-h5"
            >
              {{
                $store.getters.user.name +
                ($store.getters.user.accountSetting.useBallance
                  ? " (" + $store.getters.user.accountSetting.ballance + " ₽)"
                  : "")
              }}
            </span>

            <v-btn
              class="mx-1"
              outlined
              color="red"
              v-if="!isEmpty($store.getters.user)"
              @click="signOut"
            >
              Выход
            </v-btn>
            <v-btn v-else class="mx-1" outlined color="orange" @click="signIn">
              Вход
            </v-btn>
          </template>
        </v-app-bar>
        <!--#region Mobile Header  -->
        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="orange--text text--accent-4"
            >
              <v-list-item disabled>
                <v-list-item-content class="text-h5 text-center">
                  <div class="primary--text font-weight-medium">
                    <span class="orange--text">Лин-Эйр</span>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item to="/find-tkt">
                <v-list-item-icon>
                  <v-icon>la-search</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Найти рейс</v-list-item-title>
              </v-list-item>

              <v-list-item to="/solded-tickets">
                <v-list-item-icon>
                  <v-icon>mdi-ticket-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Билеты</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>
            </v-list-item-group>
          </v-list>
          <v-container>
            <v-row justify="space-around" align-content="end">
              <v-btn
                v-if="!isEmpty($store.getters.user)"
                class="col-auto"
                @click="signOut"
              >
                Выход
              </v-btn>
              <v-btn v-else class="col-auto" @click="signIn"> Вход </v-btn>
            </v-row>
          </v-container>
        </v-navigation-drawer>
        <!--#endregion  -->
      </div>

      <div ref="content">
        <v-container fluid>
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </v-container>
      </div>
      <error-modal
        v-if="!$route.meta.noError"
        ref="error"
        v-model="$store.state.error.show"
        :error="$store.state.error.value"
      ></error-modal>
      <snackbar
        v-model="$store.state.snackbar.show"
        :message="$store.state.snackbar.message"
        :color="$store.state.snackbar.color"
        :content-class="$store.state.snackbar.contentClass"
        :timeout="$store.state.snackbar.timeout"
      >
      </snackbar>
      <div v-if="!$route.meta.noFooter" ref="footer">
        <main-footer></main-footer>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import loginer from "@/services/loginer";
import KKTShift from "@/components/layout/KKTShift";
import MainFooter from "@/components/layout/MainFooter";
import ConfirmDialog from "@/components/UI/ConfirmDialog.vue";
import { isEmpty } from "@/services/support";
import ErrorModal from "@/components/UI/ErrorModal.vue";
import Snackbar from "@/components/UI/Snackbar.vue";

export default {
  name: "Layout",
  components: { Snackbar, KKTShift, MainFooter, ConfirmDialog, ErrorModal },
  data() {
    return {
      drawer: false,
      group: null,
      footerPadding: 0,
      txt: "",
    };
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    console.log(process.env);
  },
  methods: {
    signIn() {
      this.$router.push({ name: "login" });
    },
    signOut() {
      this.$store.dispatch({
        type: "setActions",
        accept: () => {
          loginer.signOut();
          this.$router.push({ name: "login" });
        },
      });
      this.$store.commit("setMessage", "что хотите выйти");
      this.$store.commit("openDialog", true);
      this.isTestPult = false;
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    onResize() {
      let header = this.$refs.header.offsetHeight;
      let footer = this.$refs.footer.offsetHeight;
      let win = window.innerHeight;
      this.footerPadding = win - footer - header - 1;
      this.$refs.content.style.minHeight = this.footerPadding + "px";
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    ChangePultType() {
      this.$store.commit("changePult");
    },
  },
  computed: {
    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
  },
};
</script>

<style scoped></style>
