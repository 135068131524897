import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#000000",
        secondary: "#FFA014",
        accent: "#D9C8BC",
        error: "#F44336",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "FFA014",
        item1: "#cfd8dc",
        item2: "#b2dfdb",
        window: "#dfdfdf",
      },
      options: {
        
      },
    },
  },
});

export default vuetify;
