<template>
  <v-footer class="window">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" id="contacts">
          <div class="text-h5">Наши контакты</div>
          <div
            class="light-green accent-4 mt-2 mb-8"
            style="height: 2px; width: 35%"
          ></div>
          <div>
            <a
              class="text-decoration-none"
              :href="$store.state.company.AddresLink"
              target="_blank"
            >
              <v-icon color="orange" class="mr-3" large>la-map-marker</v-icon>
              {{ $store.state.company.Addres }}
            </a>
          </div>
          <div class="my-5">
            <a
              class="text-decoration-none"
              :href="$store.state.company.PhoneLink"
            >
              <v-icon color="orange" class="mr-3" large>la-phone</v-icon>
              {{ $store.state.company.Phone }}
            </a>
          </div>
          <div>
            <a
              class="text-decoration-none"
              :href="$store.state.company.EmailLink"
            >
              <v-icon color="orange" class="mr-3" large
                >la-envelope-open</v-icon
              >
              {{ $store.state.company.Email }}
            </a>
          </div>
        </v-col>
        <v-col cols="2" md="2">
          <v-col>
            <div class="text-h5">Наши партнеры</div>
            <div
              class="light-green accent-4 mt-2 mb-8"
              style="height: 2px; width: 15%"
            ></div>
          </v-col>
          <v-col>
            <div>
              <v-img
                :src="require('@/assets/tkt-list/TCH_logo_Ru.png')"
              />
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row justify="center" class="text--disabled">
        &copy;ЛИН-ЭЙР {{ new Date().getFullYear() }}
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Validator from "../../mixins/Validators";

export default {
  name: "MainFooter",
  mixins: [Validator],
  data() {
    return {
      emailNewsletter: "",
    };
  },
  methods: {
    subscribe() {
      if (!!this.emailNewsletter && this.validateEmail(this.emailNewsletter)) {
        console.log(this.emailNewsletter);
      }
    },
  },
};
</script>
